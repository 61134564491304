import { styled } from '@mui/material/styles';

export const BarContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const Bar = styled('div')<{ complete: Boolean }>(({ complete, theme }) => ({
  marginRight: theme.spacing(1),
  width: '13px',
  height: '1px',
  background: complete ? theme.palette.background.inverse : theme.palette.text.secondary,
}));

export const Count = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontSize: '12px',
  color: theme.palette.text.secondary,

  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(1),
  },
}));

export const StepCounterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  cursor: 'default',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row-reverse',
  },
}));
