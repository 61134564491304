import { styled } from '@mui/material/styles';

export const BackLink = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
}));
