import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import { PaymentMethodApiResponse, PaymentMethodRequest } from './payment-methods.model';

const BASE_URL = '/a/api/payments-methods';

export const paymentMethodsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PaymentMethods],
  })
  .injectEndpoints({
    endpoints: builder => {
      const paymentMethods = builder.query<PaymentMethodApiResponse, PaymentMethodRequest>({
        query: ({
          investorAccountId,
          investmentAmount,
          urlHashes,
          rolloverAmountAvailable,
          rolloverInvestmentIds,
          rolloverSourceUrlHash,
        }) => ({
          url: `${BASE_URL}/${investorAccountId}`,
          method: HttpMethods.Get,
          params: {
            investmentAmount,
            urlHash: urlHashes,
            rolloverAmountAvailable,
            rolloverInvestmentIds,
            rolloverSourceUrlHash,
          },
        }),
        providesTags: [Tags.PaymentMethods],
      });

      return {
        paymentMethods,
      };
    },
  });
