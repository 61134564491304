import React from 'react';

import { Button } from '../../../content/button';

import { HeaderItem } from '../HeaderItem';
import { userNav, HelpCenter } from '../navigationConfig';
import { NavigationInputProps, NavigationLink } from '../../common';
import { NavigationItem } from '../../../types/components/navigation';
import { MobileOnly, DesktopOnly } from '../../../utils';
import { HeaderItemLoggedOut } from '../HeaderItemLoggedOut';
import { Container, UserMenuContainer, SignupHolder, LoginLinkWrapper } from './LoginArea.style';

interface LoginAreaProps extends NavigationInputProps {
  hideLogin?: boolean;
  hideSignup?: boolean;
  notificationGroup?: Array<{ menu: string; subMenu: string; value: number }>;
  preContent?: React.ReactNode;
  midContent?: React.ReactNode;
  postContent?: React.ReactNode;
  activeItem?: NavigationItem;
  activeSubItem?: NavigationItem;
  hideUserNav?: boolean;
  customSignUpButton?: React.ReactNode;
}

export const LoginArea: React.FunctionComponent<LoginAreaProps> = props => {
  const {
    activeItem,
    activeSubItem,
    hideLogin,
    hideSignup,
    hideUserNav,
    userState,
    customSignUpButton,
  } = props;

  return (
    <Container id="login-area" data-cy="login-area">
      {!hideUserNav && (
        <>
          <DesktopOnly>
            <UserMenuContainer>
              {userNav.map(item => (
                <HeaderItem
                  key={item.key}
                  activeItem={activeItem}
                  activeSubItem={activeSubItem}
                  menuItem={item}
                  desktopAlignment="right"
                  {...props}
                />
              ))}
            </UserMenuContainer>
          </DesktopOnly>
          <MobileOnly>
            <UserMenuContainer>
              {userNav.map(item => (
                <HeaderItem
                  key={item.key}
                  activeItem={activeItem}
                  activeSubItem={activeSubItem}
                  menuItem={item}
                  desktopAlignment="right"
                  isMobile
                  {...props}
                />
              ))}
            </UserMenuContainer>
          </MobileOnly>
        </>
      )}

      {!userState?.loggedIn ? (
        <SignupHolder>
          <DesktopOnly>
            <HeaderItemLoggedOut
              key="help-center"
              activeItem={activeItem}
              activeSubItem={activeSubItem}
              menuItem={HelpCenter}
              isSidebarItem
              {...props}
            />
          </DesktopOnly>
          {!hideLogin && (
            <DesktopOnly>
              <LoginLinkWrapper hideSignup={hideSignup}>
                <NavigationLink
                  {...props}
                  navItem={{
                    id: 'header-login',
                    key: 'login',
                    route: '/login',
                    title: 'Log in',
                  }}
                  raw
                >
                  <Button
                    buttonType="secondary"
                    small
                    trackingId="header-login"
                    data-cy="header-login"
                    tabIndex={-1}
                  >
                    Login
                  </Button>
                </NavigationLink>
              </LoginLinkWrapper>
            </DesktopOnly>
          )}
          {!hideSignup && (
            <NavigationLink
              {...props}
              navItem={{
                id: 'header-signup-link',
                key: 'signup',
                route: '/get-started/',
              }}
              raw
            >
              <Button
                buttonType="basic"
                small
                data-cy="header-signup-link"
                trackingId="header-signup"
                tabIndex={-1}
              >
                Sign up
              </Button>
            </NavigationLink>
          )}
          {customSignUpButton}
        </SignupHolder>
      ) : (
        ''
      )}
    </Container>
  );
};
