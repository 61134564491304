import { Payment } from '../../types';
import { PaymentStatus, PaymentMethodType } from '../../enums';

export const payment: Payment = {
  date: 'November 26, 2020',
  effectiveDate: 1606399200000,
  processDate: 'November 26, 2020',
  processOn: null,
  anticipatedSettlementDate: null,
  transactionDate: 'November 26, 2020',
  transferDate: 'November 26, 2020',
  clearDate: 1606399200000,
  loanId: 57565,
  investorAccountId: 1,
  sourceName: 'Igor',
  destName: 'Luz',
  transactionType: 'REFUND',
  principal: null,
  interest: null,
  totalAmount: 150,
  transactionPrincipal: null,
  transactionInterest: null,
  paymentMethod: PaymentMethodType.ACH,
  notes: 'test again jan',
  status: PaymentStatus.Cleared,
  investmentPending: null,
  noteTitle: 'Igor - playcore invest flow',
  noteUrlHash: 'J0kt1w',
  legalEntityType: 'SPVM',
  fundDistributionType: null,
  ratePerShare: null,
  reInvestedShares: null,
  reInvestmentSharePrice: null,
  fundDistributionFees: null,
  transactionAmount: 0,
  transactionTypeDisplayName: 'Refund',
  otherName: '',
  currencyCode: 'USD',
  investmentMatured: false,
  isFund: false,
};
