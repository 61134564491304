import React, { useEffect, useRef, useState, FunctionComponent, useCallback } from 'react';
import type { Dictionary } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { ParagraphProps } from '../../content/text/Paragraph';
import {
  getNavItemVisible,
  NavigationInputProps,
  NavigationLink,
  useNavItemProps,
} from '../common';
import { DesktopOnly } from '../../utils';
import { NavigationConfig } from '../../types';

import {
  Wrapper,
  MenuItem,
  SContainer,
  ScrollContainer,
  MenuContainer,
  MenuList,
  Line,
  ExtraContainer,
} from './Submenu.style';

export interface MenuItemProps extends ParagraphProps {
  isActive: boolean;
  isTab?: boolean;
}

export interface LineProps {
  width: string;
  left: string;
  isTab?: boolean;
}
export interface SubmenuProps extends NavigationInputProps {
  menu: NavigationConfig;
  inverse?: boolean;
  sticky?: boolean;
  useTabs?: boolean;
  desktopStickyPx?: number;
  mobileStickyPx?: number;
  activeKey?: string;
  onClick?: (key: string) => void;
  className?: string;
  extra?: React.ReactNode;
  addTransition?: boolean;
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
}

export const Submenu: FunctionComponent<SubmenuProps> = props => {
  const {
    menu,
    inverse,
    sticky,
    desktopStickyPx = 74,
    mobileStickyPx = 66,
    activeKey,
    useTabs,
    onClick,
    className,
    extra = null,
    addTransition = false,
    preContent,
    postContent,
  } = props;
  const [activeItemProps, setActiveItemProps] = useState<LineProps | undefined>();
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);

  const refs = useRef<Dictionary<HTMLLIElement>>({});
  const menuRef = useRef<HTMLUListElement>(null);

  const menuUpdate = useCallback(() => {
    if (!activeKey || isEmpty(refs.current)) {
      setActiveItemProps(undefined);
      return;
    }

    const menuWidth = menuRef.current?.offsetWidth;
    const element = refs.current[activeKey];
    if (!element) {
      return;
    }

    const boundingClientRect = element.getBoundingClientRect();
    const width = boundingClientRect.width;
    const left = element.offsetLeft;
    const clientLeft = Math.round(boundingClientRect.left);

    if ((menuWidth && clientLeft + width >= menuWidth) || clientLeft < 0) {
      if (menuWidth && menuRef.current) {
        const sum =
          menuWidth / 2 - (menuWidth - clientLeft) + menuRef.current?.scrollLeft + width / 2;
        menuRef.current?.scrollTo({
          left: sum,
          behavior: 'smooth',
        });
      }
    }

    setActiveItemProps({ width: `${width}px`, left: `${left}px` });
  }, [activeKey]);

  // Fixes font slow loading issue with initial width calculation
  useEffect(() => {
    if (!fontsLoaded && activeKey) {
      setTimeout(() => {
        menuUpdate();
        setFontsLoaded(true);
      }, 350);
    }
  }, [activeKey, fontsLoaded, menuUpdate]);

  useEffect(() => {
    menuUpdate();
  }, [menuUpdate]);

  const navProps = useNavItemProps(props);

  const items = menu.map(item => {
    const { key } = item;
    const itemVisible = getNavItemVisible(item, navProps);
    return itemVisible ? (
      <MenuItem
        ref={element => {
          if (!element) return;
          refs.current[`${element.getAttribute('data-key')}`] = element;
        }}
        key={key}
        data-key={key}
        isActive={activeKey === key}
        isTab={useTabs}
        semiBold
        inverse={!inverse ? inverse : !(inverse && activeKey === key && useTabs)}
      >
        <NavigationLink
          navItem={{ ...item, callback: () => onClick && onClick(key) }}
          raw
          {...props}
        />
      </MenuItem>
    ) : null;
  });

  return (
    <Wrapper
      data-cy="submenu"
      sticky={sticky}
      desktopStickyPx={desktopStickyPx}
      mobileStickyPx={mobileStickyPx}
      inverse={inverse}
      addTransition={addTransition}
    >
      {preContent}
      <SContainer>
        <ScrollContainer>
          <MenuContainer className={className}>
            <MenuList useTabs={useTabs} ref={menuRef}>
              {items}
              {activeKey && (
                <Line data-cy="submenu-highlight" isTab={useTabs} {...activeItemProps} />
              )}
            </MenuList>
            {extra && (
              <ExtraContainer>
                <DesktopOnly>{extra}</DesktopOnly>
              </ExtraContainer>
            )}
          </MenuContainer>
        </ScrollContainer>
      </SContainer>
      {postContent}
    </Wrapper>
  );
};
