import styled from 'styled-components';

import { TitleTag, Paragraph } from '../../content/text';

import { media } from '../../themes';

export const Wrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  background: ${props => props.theme.colors.background_inverse};

  ${props => props.theme.shadows.elevation_2};
  // NOTE: Elevation/02 that is shown in the figma does not match the one in the theme
  // box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.2), 0px 3px 7px -3px rgba(0, 0, 0, 0.25);
`;

export const TopBar = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  background-color: ${props => props.theme.colors.active_alt};
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};
  gap: ${props => props.theme.spacing.xs};
`;

export const Content = styled.div`
  padding: ${props => props.theme.spacing.sm};
  gap: ${props => props.theme.spacing.m};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  flex-wrap: wrap;

  ${media.tablet`
    flex-wrap: nowrap;
    padding: ${props => props.theme.spacing.m};
  `};
`;

export const Secondary = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  ${media.tablet`
    flex: 0 1 auto;
    max-width: 55%;
  `};
`;

export const Subtitle = styled(TitleTag)``;

// Secondary
export const SecondaryViewPort = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  gap: ${props => props.theme.spacing.s};
  scroll-snap-type: both mandatory;
  overscroll-behavior-y: contain;

  ${media.tablet`
    gap: ${props => props.theme.spacing.ml};
  `};

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const SecondaryItem = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  min-width: 100px;
  gap: ${props => props.theme.spacing.xs};

  scroll-snap-stop: always;
  scroll-snap-align: start;
`;

export const DividerItem = styled.div`
  display: flex;
  flex: 0 0 1px;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #3e3e40;

  &:last-child {
    display: none;
  }
`;

export const SecondaryItemTitle = styled.div`
  position: relative;
  padding-right: ${props => props.theme.spacing.m};
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  gap: ${props => props.theme.spacing.xxs};
`;

export const SecondaryItemTitleTooltipWrapper = styled.div`
  position: absolute;
  right: -${props => props.theme.spacing.xxs};
`;

export const SecondaryItemContent = styled(Paragraph)``;
