import { alpha, styled } from '@mui/material/styles';

export const NAV_HEIGHT = 68;

export const Wrapper = styled('div')(() => ({
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 998,
}));

export const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  height: `${NAV_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'space-between',
  transition: theme.transitions.create('background', {
    duration: 500,
  }),
  boxSizing: 'border-box',
  '&.transparent': {
    background: alpha(theme.palette.common.white, 0),
    borderBottom: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    height: '74px',
    padding: 0,
  },
  '@media print': {
    display: 'none',
  },
}));

export const Flex = styled('div')({
  display: 'flex',
});

export const LogoWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingLeft: theme.spacing(5),
  a: {
    display: 'flex',
  },
  img: {
    width: '120px',
    minHeight: '39px',
    [theme.breakpoints.up('lg')]: {
      width: '140px',
      height: '100%',
      minHeight: '50px',
    },
  },
}));

export const MenuContainer = styled('div')<{ sideMenuOpen: boolean }>(
  ({ theme, sideMenuOpen }) => ({
    position: 'fixed',
    bottom: 0,
    top: 0,
    width: '100%',
    transition: theme.transitions.create('opacity', {
      duration: 500,
    }),
    zIndex: 997,
    right: sideMenuOpen ? 0 : '-100%',
    opacity: sideMenuOpen ? 1 : 0,
    [theme.breakpoints.up('lg')]: {
      position: 'static',
      opacity: 1,
      display: 'flex',
      alignSelf: 'center',
    },
  })
);

export const Menu = styled('div')<{ sideMenuOpen: boolean }>(({ theme, sideMenuOpen }) => ({
  position: 'fixed',
  bottom: 0,
  top: 0,
  width: '100%',
  zIndex: 998,
  background: theme.palette.background.default,
  overflowY: 'auto',
  right: sideMenuOpen ? 0 : '-100%',
  transition: theme.transitions.create('right', {
    duration: 500,
  }),
  padding: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: `0 ${theme.spacing(6)}`,
    position: 'static',
    bottom: 0,
    top: 'auto',
    width: 'auto',
    overflowY: 'visible',
  },
}));

export const MenuItemsContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(25),
  [theme.breakpoints.up('lg')]: {
    flexGrow: 1,
    display: 'flex',
    padding: `0 ${theme.spacing(5)} 0 ${theme.spacing(5)}`,
    alignItems: 'baseline',
  },
}));

export const HamburgerWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(4),
  verticalAlign: 'top',
  WebkitTapHighlightColor: 'transparent',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const SignButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> a': {
    flex: 1,
    display: 'inline-flex',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const Backdrop = styled('div')<{ visible: boolean }>(({ theme, visible }) => ({
  background: alpha(theme.palette.background.default, 0.2),
  position: 'fixed',
  top: '64px',
  width: '100vw',
  transition: 'height 0ms 400ms, opacity 400ms 0ms',
  opacity: 0,
  height: 0,
  overflow: 'hidden',
  ...(visible && {
    height: '100vh',
    opacity: 1,
    transition: 'height 0ms 0ms, opacity 600ms 0ms',
  }),
}));

export const MobileMenuFooter = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  position: 'fixed',
  width: 'calc(100% - 50px)',
  padding: `${theme.spacing(6)} 0`,
  bottom: 0,
  [theme.breakpoints.up('md')]: {
    maxWidth: '270px',
  },
}));

export const MobileMenuFooterHolder = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  marginTop: theme.spacing(5),
}));

export const Separator = styled('hr')(({ theme }) => ({
  borderColor: theme.palette.border.strong,
  opacity: 0.3,
  '&:last-child': {
    display: 'none',
  },
}));

export const NavContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(6),
}));

export const MobileLogoWrap = styled(LogoWrap)(({ theme }) => ({
  paddingLeft: 0,
  [theme.breakpoints.up('md')]: {
    '.nav-logo': {
      display: 'none',
    },
  },
}));
