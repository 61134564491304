import { styled } from '@mui/material/styles';

export const NavigationIconWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const NavigationIconAnimated = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('lg')]: {
    marginRight: '4px',
  },
}));

export const NavigationIcon = styled('img')`
  margin-right: 10px;
  max-height: 32px;
`;
