import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isString from 'lodash/isString';

import { NavigationConfig } from '../../../types/components/navigation';
import {
  getNavItemSubMenu,
  getNavItemTitle,
  getNavItemVisible,
  NavigationLink,
  useNavItemProps,
} from '../../common';
import { getNavigationValue, getSizeVariation } from '../common';
import { SubmenuSection } from '../SubmenuSection/SubmenuSection';
import { HeaderItemProps } from './HeaderItem.model';

import {
  ArrowIcon,
  MenuItem,
  MenuLinkContainer,
  NotificationIcon,
  NotificationIndicator,
  SButton,
  SParagraph,
  SubMenu,
  SubMenuItem,
  Title,
} from './HeaderItem.style';

export const HeaderItem: React.FunctionComponent<HeaderItemProps> = props => {
  const {
    menuItem,
    activeItem,
    isMobile,
    isSidebarItem,
    desktopAlignment = 'left',
    notificationGroup,
  } = props;

  const menuLinkElement = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(e => {
    if (menuLinkElement.current && e.target) {
      if (!menuLinkElement.current.contains(e.target as Node)) {
        setMenuOpen(false);
      }
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
    }
  }, [onClickOutside]);

  const [menuOpen, setMenuOpen] = useState(false);

  const navProps = useNavItemProps(props);

  const itemSelected = menuItem.key === activeItem?.key;

  const itemInteractive = useMemo(() => {
    const renderTitle = getSizeVariation(
      getNavigationValue(menuItem.title, navProps, menuItem),
      isMobile
    );
    return isString(renderTitle);
  }, [isMobile, menuItem, navProps]);

  const itemTitle = useMemo(() => {
    return getNavItemTitle(menuItem, navProps);
  }, [menuItem, navProps]);

  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);

  const itemSubMenu = useMemo(() => {
    return getNavItemSubMenu(menuItem, navProps) as NavigationConfig;
  }, [menuItem, navProps]);

  if (!itemVisible) {
    return null;
  }

  return (
    <MenuItem
      key={menuItem.key}
      data-js-header-item-menu-item
      isSidebarItem={isSidebarItem}
      seperator={menuItem.seperator}
    >
      <Title
        onMouseEnter={() => !isMobile && itemSubMenu && menuItem.itemsDropdown && setMenuOpen(true)}
        onMouseLeave={() =>
          !isMobile && itemSubMenu && menuItem.itemsDropdown && setMenuOpen(false)
        }
        isSidebarItem={isSidebarItem}
      >
        <MenuLinkContainer
          ref={menuLinkElement}
          isSidebarItem={isSidebarItem}
          active={menuOpen}
          isButton={menuItem?.type === 'button'}
          selected={itemSelected}
          onClick={e => {
            if (isMobile && itemSubMenu) {
              setMenuOpen(!menuOpen);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          interactive={itemInteractive}
        >
          {itemSubMenu ? (
            <a
              href="#/"
              onClick={e => e.preventDefault()}
              data-cy={`header-${menuItem?.key}-mobile`}
            >
              {itemTitle}
            </a>
          ) : (
            <NavigationLink navItem={{ ...menuItem, id: `header-${menuItem?.key}` }} raw {...props}>
              {menuItem?.type === 'button' ? (
                <SButton data-cy={`header-${menuItem?.key}-item`}>{itemTitle}</SButton>
              ) : (
                <>{itemTitle}</>
              )}
            </NavigationLink>
          )}
          {itemSubMenu && (isMobile || menuItem.itemsDropdown) && (
            <ArrowIcon isSidebarItem={isSidebarItem} menuOpen={menuOpen} />
          )}
          <NotificationIndicator>
            {notificationGroup?.map(group =>
              group.menu === menuItem.key && group.value ? <NotificationIcon /> : null
            )}
          </NotificationIndicator>
        </MenuLinkContainer>
        {menuOpen && itemSubMenu && (
          <SubMenu isSidebarItem={isSidebarItem} desktopAlignment={desktopAlignment}>
            {itemSubMenu.map(submenu => {
              if (submenu.items) {
                return (
                  <SubmenuSection
                    item={submenu}
                    key={`submenu-section-${submenu.key}`}
                    navProps={navProps}
                    inverse
                  />
                );
              }

              return (
                <HeaderSubMenuItem
                  {...props}
                  key={submenu.key}
                  menuItem={submenu}
                  notificationGroup={notificationGroup}
                  seperator={submenu.seperator}
                />
              );
            })}
          </SubMenu>
        )}
      </Title>
    </MenuItem>
  );
};

export const HeaderSubMenuItem: React.FunctionComponent<HeaderItemProps> = props => {
  const { menuItem, activeSubItem, notificationGroup, isSidebarItem, seperator } = props;

  const navProps = useNavItemProps(props);

  const itemSelected = menuItem.key === activeSubItem?.key;
  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);

  if (!itemVisible) {
    return null;
  }

  return (
    <SubMenuItem seperator={seperator} key={`submenu-${menuItem.key}`}>
      <MenuLinkContainer
        isSubItem
        isSidebarItem={isSidebarItem}
        selected={itemSelected}
        interactive
      >
        <NavigationLink navItem={{ ...menuItem, id: `header-${menuItem?.key}` }} raw {...props} />
        <NotificationIndicator>
          {notificationGroup?.map(group =>
            group.subMenu === menuItem.key && group.value ? (
              <NotificationIcon subMenu isBigNumber={group.value > 99}>
                <SParagraph variant="body1">{group.value > 99 ? '99+' : group.value}</SParagraph>
              </NotificationIcon>
            ) : null
          )}
        </NotificationIndicator>
      </MenuLinkContainer>
    </SubMenuItem>
  );
};
