import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')({
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 998,
  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 4px 0px',
});

export const Container = styled('div')(({ theme }) => ({
  background: theme.palette.NavigationHeader.background.default,
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between',
  transition: theme.transitions.create('background', {
    duration: 500,
  }),
  boxSizing: 'border-box',
  '&.transparent': {
    background: 'rgba(255, 255, 255, 0)',
    borderBottom: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    height: '74px',
    padding: 0,
  },
  '@media print': {
    display: 'none',
  },
}));

export const Flex = styled('div')({
  display: 'flex',
});

export const LogoWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingLeft: theme.spacing(5),
  a: {
    display: 'flex',
  },
  img: {
    width: 145,
    minHeight: 39,
    [theme.breakpoints.up('lg')]: {
      width: 185,
      height: '100%',
      minHeight: 50,
    },
  },
}));

export const MenuContainer = styled('div')<{ sideMenuOpen: boolean }>(
  ({ theme, sideMenuOpen }) => ({
    position: 'fixed',
    bottom: 0,
    top: '67px',
    width: '100%',
    transition: theme.transitions.create('opacity', {
      duration: 500,
    }),
    zIndex: 997,
    left: sideMenuOpen ? 0 : '-100%',
    opacity: sideMenuOpen ? 1 : 0,
    [theme.breakpoints.up('lg')]: {
      position: 'static',
      opacity: 1,
      display: 'flex',
      alignSelf: 'center',
    },
  })
);

export const Menu = styled('div')<{ sideMenuOpen: boolean }>(({ theme, sideMenuOpen }) => ({
  position: 'fixed',
  bottom: 0,
  top: '67px',
  width: '100%',
  zIndex: 998,
  background: theme.palette.NavigationHeader.background.default,
  overflowY: 'auto',
  left: sideMenuOpen ? 0 : '-100%',
  transition: 'left 0.5s',
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('lg')]: {
    position: 'static',
    bottom: 0,
    top: 'auto',
    width: 'auto',
    overflowY: 'visible',
  },
}));

export const MenuItemsContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexGrow: 1,
    display: 'flex',
    padding: '0 15px 0 15px',
    alignItems: 'baseline',
  },
}));

export const HamburgerWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(4),
  verticalAlign: 'top',
  WebkitTapHighlightColor: 'transparent',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const SignButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> a': {
    flex: 1,
    display: 'inline-flex',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));
