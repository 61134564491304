import React, { FunctionComponent, useState } from 'react';
import { styled } from '@mui/material/styles';

import { uniqueId } from 'lodash';

interface CollapsedNoteProps {
  fullNote: React.ReactChild;
}

const buttonId = uniqueId('btn_');
const containerId = uniqueId('acc_');

export const CollapsedNote: FunctionComponent<CollapsedNoteProps> = ({ fullNote, children }) => {
  const [showFullNote, setShowFullNote] = useState(false);

  return !showFullNote ? (
    <Container aria-labelledby={buttonId} id={containerId} aria-expanded="false">
      {children}
      <ReadMore
        as="span"
        id={buttonId}
        aria-controls={containerId}
        tabIndex={0}
        onClick={() => setShowFullNote(true)}
      >
        [read more]
      </ReadMore>
    </Container>
  ) : (
    <Container aria-labelledby={buttonId} id={containerId} aria-expanded="true">
      {fullNote}
    </Container>
  );
};

const Container = styled('p')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '19px',
  color: theme.palette.text.paragraphSecondary.inverse,
  textAlign: 'justify',
  padding: '5px 0',
  [theme.breakpoints.up('lg')]: {
    gridColumn: '1/span 3',
  },
}));

const ReadMore = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  margin: '0 0 0 5px',
  color: theme.palette.text.paragraphSecondary.inverse,
}));
