import { ReactNode } from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

import { CSS_THEME } from '../../../theme';

export const enum SurfaceType {
  Default = 'default',
  Outline = 'outline',
  Highlight = 'highlight',
  Skeleton = 'skeleton',
}

export interface SurfaceProps {
  children: ReactNode;
  className?: string;
  small?: boolean;
  testId?: string;
  type?: SurfaceType;
  withPadding?: boolean;
}

export interface GetSizePropsParams {
  small?: boolean;
  theme: typeof CSS_THEME;
  withPadding?: boolean;
}

export interface GetTypePropsParams {
  theme: typeof CSS_THEME;
  type?: SurfaceType;
}

export const { getTestIds, useTestIds } = genTestIds([]);
export const testIds = getTestIds('surface');
