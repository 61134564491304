import React, { FC } from 'react';
import { styled } from '@mui/material/styles';

import blackLogoImg from '../../../../assets/logos/yieldstreet/black_ys_logo_mobile.svg';
import logoImg from '../../../../assets/logos/yieldstreet/white_ys_logo_mobile.svg';

type LogoProps = {
  inverse?: boolean;
};

export const Logo: FC<LogoProps> = ({ inverse }) => <LogoImage tabIndex={-1} inverse={inverse} />;

const LogoImage = styled('div')<{ inverse?: boolean }>(({ theme, inverse }) => ({
  display: 'flex',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundImage: `url(${inverse ? logoImg : blackLogoImg})`,

  width: '145px',
  '&:active , &:focus': {
    outline: 0,
    border: 'none',
  },

  [theme.breakpoints.up('lg')]: {
    width: '185px',
  },
}));
