import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '../../../content/button';
import { DesktopOnly, MobileOnly } from '../../../utils';
import { NavigationLink } from '../../common';
import { HelpCenter, mainNavLoggedOut } from '../navigationConfig';
import { Hamburger } from '../Hamburger';
import { LoginArea } from '../LoginArea/LoginArea';
import { Logo } from '../Logo';
import { HeaderItemLoggedOut } from '../HeaderItemLoggedOut';

import {
  Backdrop,
  MobileLogoWrap,
  MobileMenuFooter,
  MobileMenuFooterHolder,
  NavContainer,
  Separator,
  Container,
  Flex,
  HamburgerWrap,
  LogoWrap,
  Menu,
  MenuContainer,
  MenuItemsContainer,
  Wrapper,
} from './HeaderViewLoggedOut.style';
import { HeaderViewProps } from './HeaderViewLoggedOut.model';

export const HeaderLoggedOut: React.FunctionComponent<HeaderViewProps> = props => {
  const {
    audiences,
    tier,
    userState,
    currentPath,
    notificationGroup,
    preContent,
    midContent,
    postContent,
  } = props;

  const menuLinkElement = useRef<HTMLDivElement>(null);

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenuOpen = useCallback(() => {
    setSideMenuOpen(!sideMenuOpen);
  }, [sideMenuOpen]);

  const onClickOutside = useCallback(e => {
    if (menuLinkElement.current && e.target) {
      if (!menuLinkElement.current.contains(e.target as Node)) {
        setDropdownOpen(false);
      }
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
    }
  }, [onClickOutside]);

  useEffect(() => {
    setSideMenuOpen(false);
    setDropdownOpen(false);
  }, [currentPath]);

  return (
    <Wrapper data-cy="header-view">
      <Backdrop visible={dropdownOpen || sideMenuOpen} />
      {preContent}
      <Container>
        <Flex>
          <HamburgerWrap>
            <Hamburger opened={sideMenuOpen} onChange={toggleMenuOpen} />
          </HamburgerWrap>
          <LogoWrap onChange={toggleMenuOpen} data-cy="yieldstreet-logo">
            <NavigationLink
              {...props}
              navItem={{
                key: 'home',
                id: 'yieldstreet-logo',
                route: userState && userState.loggedIn ? '/offerings' : '/',
                staticRoute: true,
                ariaLabel: 'Yieldstreet logo',
              }}
              raw
            >
              <Logo />
            </NavigationLink>
          </LogoWrap>
        </Flex>
        <MenuContainer sideMenuOpen={sideMenuOpen}>
          <Menu sideMenuOpen={sideMenuOpen} ref={menuLinkElement}>
            <DesktopOnly>
              <MenuItemsContainer>
                {mainNavLoggedOut.map(item => (
                  <HeaderItemLoggedOut
                    key={item.key}
                    menuItem={item}
                    currentPath={currentPath}
                    notificationGroup={notificationGroup}
                    setDropdownOpen={setDropdownOpen}
                    {...props}
                  />
                ))}
              </MenuItemsContainer>
            </DesktopOnly>
            <MobileOnly>
              <NavContainer>
                <MobileLogoWrap onChange={toggleMenuOpen}>
                  <NavigationLink
                    {...props}
                    navItem={{
                      key: 'home',
                      route: userState && userState.loggedIn ? '/offerings' : '/',
                      staticRoute: true,
                    }}
                    raw
                    className="nav-logo"
                  >
                    <Logo />
                  </NavigationLink>
                </MobileLogoWrap>
                <HamburgerWrap>
                  <Hamburger opened={sideMenuOpen} onChange={toggleMenuOpen} />
                </HamburgerWrap>
              </NavContainer>
              <MenuItemsContainer>
                <Separator />
                {mainNavLoggedOut.map(item => (
                  <HeaderItemLoggedOut
                    key={item.key}
                    menuItem={item}
                    currentPath={currentPath}
                    notificationGroup={notificationGroup}
                    isSidebarItem
                    isMobile
                    setDropdownOpen={setDropdownOpen}
                    setSideMenuOpen={setSideMenuOpen}
                    {...props}
                  />
                ))}
                <HeaderItemLoggedOut
                  key="help-center"
                  menuItem={HelpCenter}
                  currentPath={currentPath}
                  notificationGroup={notificationGroup}
                  isSidebarItem
                  isMobile
                  setDropdownOpen={setDropdownOpen}
                  setSideMenuOpen={setSideMenuOpen}
                  {...props}
                />
              </MenuItemsContainer>
              <MobileMenuFooter>
                <Separator />
                <MobileMenuFooterHolder>
                  <NavigationLink
                    {...props}
                    navItem={{
                      id: 'header-nav-signuplink',
                      key: 'signup',
                      route: '/get-started/',
                    }}
                    raw
                  >
                    <Button buttonType="basic" trackingId="mobile-menu-signup" small>
                      Sign up
                    </Button>
                  </NavigationLink>
                  <NavigationLink
                    {...props}
                    navItem={{
                      id: 'header-nav-login',
                      key: 'login',
                      route: '/login',
                      title: 'Log in',
                    }}
                    raw
                  >
                    <Button buttonType="secondary" trackingId="mobile-menu-login" small>
                      Login
                    </Button>
                  </NavigationLink>
                </MobileMenuFooterHolder>
              </MobileMenuFooter>
            </MobileOnly>
          </Menu>
        </MenuContainer>
        <LoginArea audiences={audiences} tier={tier} {...props} />
      </Container>
      {midContent}
      {postContent}
    </Wrapper>
  );
};
