import { SignupPortfolioValues } from '@yieldstreet/platform-kit';
import { TEST_ID_ATTR } from '../../testing';

export const SignupPortfolioOptions = [
  {
    value: SignupPortfolioValues.UNDER_50K,
    label: 'Less than $50k',
    [TEST_ID_ATTR]: 'quiz-portfolio-UNDER_50K',
  },
  {
    value: SignupPortfolioValues.BETWEEN_50_250,
    label: '$50k-$250k',
    [TEST_ID_ATTR]: 'quiz-portfolio-BETWEEN_50_250',
  },
  {
    value: SignupPortfolioValues.BETWEEN_250_1M,
    label: '$250k-$1M',
    [TEST_ID_ATTR]: 'quiz-portfolio-BETWEEN_250_1M',
  },
  {
    value: SignupPortfolioValues.BETWEEN_1M_5M,
    label: '$1M-$5M',
    [TEST_ID_ATTR]: 'quiz-portfolio-BETWEEN_1M_5M',
  },
  {
    value: SignupPortfolioValues.MORE_5M,
    label: '$5M+',
    [TEST_ID_ATTR]: 'quiz-portfolio-MORE_5M',
  },
];

export const SignupPortfolioLabels = {
  title: 'What is the approximate size of your portfolio?',
  subtitle:
    'Investments on Yieldstreet have a variety of yields, terms, and minimums. Understanding the size of your overall investment portfolio helps us assess which products may best meet your objectives.',
};
