import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { globalHistory } from '@reach/router';
import {
  MarketingContainer,
  ConsentManager,
  ModalProvider,
  theme2,
  SpacingTokens as GlobalStyles,
} from '@yieldstreet/ui-kit';
import { storeTrackingMeta, parseQueryTrackingParams } from '@yieldstreet/tool-kit';
import { ThemeProvider } from 'styled-components';
import Head from 'components/head';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import SimpleLayout, { SimpleLayoutProps } from 'components/layouts/SimpleLayout/SimpleLayout';
import { PageProps, WrapPageElementBrowserArgs } from 'gatsby';

import { CSS_THEME } from '@yieldstreet/ui-kit/theme';
import { ThemeProvider as CssVarsProvider } from '@mui/material/styles';

import { getIsProd } from 'utils/helpers';

const TEST_SEGMENT_WRITE_KEY = 'KxujeXPvYj7vPVHUftA5Vgt9Djdzmtf3';
const PROD_SEGMENT_WRITE_KEY = 'xUD6CfwtzPHYj9g2tctWM31gvr9wCCjc';

export interface ServerDataProps {
  serverData: { visitorId: string; modifications: object[] };
}

export type GatsbyPageProps = Omit<PageProps, 'serverData'> & ServerDataProps;

interface PageWrapperProps {
  pageProps: GatsbyPageProps;
}

export interface GatsbyWrapPageElementBrowserArgs
  extends Pick<WrapPageElementBrowserArgs, 'element'> {
  props: GatsbyPageProps;
}

const staticPageRoutes: { [route: string]: SimpleLayoutProps } = {
  '/six-tips-on-investing-during-periods-of-uncertainty': { hideLogin: true },
  '/five-ways-to-make-money-outside-the-stock-market': { hideLogin: true },
  '/generate-passive-income': { hideLogin: true },
  '/how-often-are-you-receiving-payments': { hideLogin: true },
  '/five-investments-to-consider-during-economic-recession': {
    hideLogin: true,
  },
  '/promotion/structured-notes-investing': { hideLogin: true },
  '/promotion/supply-chain-investing': { hideLogin: true },
  '/promotion/short-term-notes': { hideLogin: true },
  '/top-alternative-investments-trends': { hideLogin: true },
  '/promotion/emerging-tech-brew': { hideHeader: true },
  '/promotion/mint': { hideHeader: true },
  '/promotion/mint-intuit': { hideHeader: true },
  '/promotion/seeking-alpha': { hideHeader: true },
  '/promotion/deposit-bonus': { hideHeader: true },
  '/promotion/vip-deposit-bonus': { hideHeader: true },
  '/promotion/totetaxi': { hideHeader: true },
  '/promotion/blade': { hideHeader: true },
  '/promotion/armory-show': { hideHeader: true },
  '/promotion/armory-show-mike-perry': { hideHeader: true },
  '/promotion/morningbrew': { hideHeader: true },
  '/promotion/biggameweekend': { hideHeader: true },
  '/promotion/basquiat-king-pleasure': { hideHeader: true },
  '/promotion/the-masters': { hideHeader: true },
  '/promotion/augusta-wifi': { hideHeader: true },
  '/promotion/augusta': { hideLogin: true },
  '/promotion/investing-for-income': { hideLogin: true },
  '/promotion/nice-to-meet-you': { hideHeader: true },
  '/promotion/nygiants-signup': { hideHeader: true, hideFooter: true },
  '/promotion/nygiants': { hideHeader: true, hideFooter: true },
  '/promotion/summerparty-yieldstreet': { hideHeader: true, hideFooter: true },
  '/promotion/wallethacks': { hideHeader: true, hideFooter: true },
  '/promotion/private-clients': { hideHeader: true, hideFooter: true },
  '/promotion/jowma': { hideHeader: true, hideFooter: true },
  '/promotion/peerstreet': { hideHeader: true, hideFooter: true },
  '/promotion/capitalone': { hideHeader: true, hideFooter: true },
  '/promotion/yieldstreet-foundation-israel-fundraiser': { hideHeader: true, hideFooter: true },
  '/access-restricted': { hideHeader: true, hideFooter: true },
  '/unsupported-browser': { hideLogin: true, hideSignup: true },
  '/private-clients': { hideLogin: true },
  '/myequity': {},
  '/united-hatzalah': {},
  '/cadre-private-markets': {},
  '/referral/tim-schott': { hideHeader: true, hideFooter: true },
};

const authenticatedPageRoutes: any = [];

const normalizePath = (pathname: string) => pathname.replace(/\/$/, '');

const isStaticPath = (pathname: string) =>
  !!Object.keys(staticPageRoutes).find((route: string) => route === normalizePath(pathname));
const isAuthenticatedPath = (pathname: string) =>
  !!authenticatedPageRoutes.find((route: string) => route === normalizePath(pathname));

const getStaticProps = (pathname: string) =>
  isStaticPath(pathname) ? staticPageRoutes[normalizePath(pathname)] : {};

const PageElementWrapper: FunctionComponent<PageWrapperProps> = ({ children, pageProps }) => {
  const { location } = pageProps;

  const [isStatic] = useState(() => isStaticPath(location.pathname));
  const [staticProps] = useState<Partial<SimpleLayoutProps>>(() =>
    getStaticProps(location.pathname)
  );
  const [shouldBeAuthenticated] = useState(() => isAuthenticatedPath(location.pathname));

  useEffect(() => {
    storeTrackingMeta(parseQueryTrackingParams(location.search));
  }, [location]);

  const segmentWriteKey = useMemo(() => {
    const isProd = getIsProd(location.hostname);
    return isProd ? PROD_SEGMENT_WRITE_KEY : TEST_SEGMENT_WRITE_KEY;
  }, []);

  // Make sure that when you run `gatsby build` and `gatsby serve` the page renders correctly.
  // Also note that `wrapPageElement` function is being used BOTH in `gatsby-browser.js` and `gatsby-ssr.js`.
  // The reason why the layout is being rendered here is to avoid a re-render on every page change and have proper SPA behaviour.

  return (
    <ThemeProvider theme={theme2}>
      <CssVarsProvider theme={CSS_THEME}>
        <ModalProvider history={globalHistory}>
          <Head />
          <ConsentManager segmentWriteKey={segmentWriteKey} />
          <GlobalStyles />
          {isStatic ? (
            <SimpleLayout location={location} {...staticProps}>
              {children}
            </SimpleLayout>
          ) : (
            <MainLayout location={location} shouldBeAuthenticated={shouldBeAuthenticated}>
              <MarketingContainer>{children}</MarketingContainer>
            </MainLayout>
          )}
        </ModalProvider>
      </CssVarsProvider>
    </ThemeProvider>
  );
};

export const wrapPageElement = ({ element, props }: GatsbyWrapPageElementBrowserArgs) => {
  return <PageElementWrapper pageProps={props}>{element}</PageElementWrapper>;
};

export default PageElementWrapper;
