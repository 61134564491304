import { ThemeColor } from './palette.model';

type secondary = 'paleGreen' | 'sand' | 'palePurple';

export const SECONDARY: Record<secondary, ThemeColor> = {
  paleGreen: {
    50: '#fafcfb',
    100: '#eff5f3',
    200: '#e7f0ee',
    300: '#dceae6',
    400: '#d5e5e1',
    500: '#cbdfd9',
    600: '#b9cbc5',
    700: '#909e9a',
    800: '#707b77',
    900: '#555e5b',
  },
  sand: {
    50: '#fcfcfb',
    100: '#f6f4f1',
    200: '#f2efea',
    300: '#ece8e1',
    400: '#e8e4db',
    500: '#e2ddd2',
    600: '#cec9bf',
    700: '#a09d95',
    800: '#7c7a74',
    900: '#5f5d58',
  },
  palePurple: {
    50: '#fcfbfc',
    100: '#f7f1f6',
    200: '#f3eaf2',
    300: '#eee1ec',
    400: '#eadbe8',
    500: '#e5d2e2',
    600: '#d0bfce',
    700: '#a395a0',
    800: '#7e747c',
    900: '#60585f',
  },
};
