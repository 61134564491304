import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const LoginButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  border: 'none',
  background: theme.palette.NavigationHeader.button.secondary?.default,
  '&:hover ': {
    background: theme.palette.NavigationHeader.button.secondary?.hover,
  },
  '&:active': {
    background: theme.palette.NavigationHeader.button.secondary?.active,
    border: `1px solid ${theme.palette.border.extra_strong}`,
    color: `${theme.palette.text.header.inverse} !important`,
  },
}));

export const MobileMenuFooter = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(10)} ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
  a: {
    display: 'inline-block',
    img: {
      height: '45px',
    },
    margin: '15px 10px 0 0',
  },
}));
