import { Cadence, PaymentMethodType } from '@yieldstreet/platform-kit';

import { cadenceCopies, CadenceCopy, alternativeCadenceCopies } from './confirmationProcess.model';

export const useOfferingCadenceCopy = (
  cadence: Cadence,
  paymentMethod: PaymentMethodType = PaymentMethodType.SYNP,
  shouldShowAlternativeCopy?: boolean
) => {
  const copy = shouldShowAlternativeCopy ? alternativeCadenceCopies : cadenceCopies;
  const defaultCopy = copy?.[PaymentMethodType?.SYNP]?.[Cadence.DAILY];

  const cadenceCopy = (copy?.[paymentMethod]?.[cadence] || defaultCopy) as CadenceCopy;

  return {
    ...cadenceCopy,
    rollover: 'We have received your investment request and will start processing soon.',
  };
};
