import React from 'react';
import { LoginArea } from '../LoginArea';
import { Logo } from '../Logo';
import { NavigationInputProps, NavigationLink } from '../../common';
import { Wrapper, Container, Flex, LogoWrap } from '../styles';

export interface SimpleHeaderViewProps extends NavigationInputProps {
  hideLogin?: boolean;
  hideSignup?: boolean;
  hideUserNav?: boolean;
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
  customSignUpButton?: React.ReactNode;
  logoAlt?: string;
}

export const SimpleHeader: React.FunctionComponent<SimpleHeaderViewProps> = ({
  hideLogin,
  hideSignup,
  hideUserNav,
  preContent,
  postContent,
  customSignUpButton,
  logoAlt,
  ...otherProps
}) => {
  return (
    <Wrapper data-cy="simple-header">
      {preContent}
      <Container>
        <Flex>
          <LogoWrap>
            <NavigationLink
              navItem={{ key: 'home', route: '/', staticRoute: true }}
              title={logoAlt}
              raw
              {...otherProps}
            >
              <Logo inverse />
            </NavigationLink>
          </LogoWrap>
        </Flex>
        <LoginArea
          hideLogin={hideLogin}
          hideSignup={hideSignup}
          hideUserNav={hideUserNav}
          customSignUpButton={customSignUpButton}
        />
      </Container>
      {postContent}
    </Wrapper>
  );
};
