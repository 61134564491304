module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-asset-path@3.0.4_gatsby@4.18.2_patch_hash=fd6mwyghlqzpwa4vwfw2fxs7ny_@types+nod_ndqa2oo62d7pqfq33svj52xyfy/node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"paths":["static","page-data"],"fileTypes":["js","map","css","svg","mp4","webm","webp","jpg","png","gif","pdf","json"]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_@babel+core@7.22._z7hv4yxegar7klifyan3peuc2i/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+css@11.10.6_@emotion+react@11.13.0_@types+react@17.0_tqeblyktx2vsafngy4vn4typzi/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.18.2_patch_hash=fd6mwyghlqzpwa4vwfw2fxs7ny_@types+node@18.19.31_babel-eslint@10.1.0__5otxlgq3d3roga3h7krve7xzdy/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
