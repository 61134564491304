import { styled } from '@mui/material/styles';

import type { LinkStyleProps } from '../../../content/link';

export const Container = styled('div')({
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  paddingRight: 16,
});

export const UserMenuContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const SignupHolder = styled('div')(({ theme }) => ({
  alignItems: 'center',
  position: 'relative',
  display: 'inline-flex',
  fontSize: 14,
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '10px 15px',
  },
}));

export const LoginLinkWrapper = styled('span')<LinkStyleProps & { hideSignup?: boolean }>(
  ({ hideSignup, theme }) => ({
    display: hideSignup ? 'inline-block' : 'none',
    margin: '0 10px 0 20px',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  })
);
