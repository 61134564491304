import { HttpMethods } from '../../enums/http';
import {
  AuthAPIResponse,
  PasswordRegisterParams,
  PasswordLoginParams,
  SocialLoginParams,
  SocialLoginLinkIdentityParams,
  ImpersonateLoginParams,
  LoginOTPParams,
} from './auth.model';
import { Tags, restApi } from '../common';

export const authApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserState, Tags.UserTwoFactorAuthStatus],
  })
  .injectEndpoints({
    endpoints: builder => {
      const passwordRegister = builder.mutation<AuthAPIResponse, PasswordRegisterParams>({
        query: data => {
          return {
            url: `/a/api/user/register`,
            method: HttpMethods.Post,
            data,
          };
        },
        invalidatesTags: [Tags.UserState],
      });

      const passwordLogin = builder.mutation<AuthAPIResponse, PasswordLoginParams>({
        query: data => {
          return {
            url: `/a/api/user/login`,
            method: HttpMethods.Post,
            data,
          };
        },
        invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus],
      });

      const socialLogin = builder.mutation<AuthAPIResponse, SocialLoginParams>({
        query: ({ token, persistent, anonymousId, recaptchaV3, authData, userDetails }) => {
          return {
            url: '/api/auth/login',
            method: HttpMethods.Post,
            data: {
              persistent,
              anonymousId,
              recaptchaV3,
              ...authData,
              ...userDetails,
            },
            headers: { Authorization: `Bearer ${token}` },
          };
        },
        invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus],
      });

      const socialIdentityLink = builder.mutation<AuthAPIResponse, SocialLoginLinkIdentityParams>({
        query: ({ password }) => {
          return {
            url: '/api/auth/link-identity',
            method: HttpMethods.Post,
            data: {
              password,
            },
          };
        },
        invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus],
      });

      const impersonateLogin = builder.mutation<AuthAPIResponse, ImpersonateLoginParams>({
        query: data => {
          return {
            url: '/api/admin/auth/impersonation/login',
            method: HttpMethods.Post,
            data,
          };
        },
        invalidatesTags: [Tags.UserState],
      });

      const logout = builder.mutation({
        query: () => {
          return {
            url: '/a/api/user/logout',
            method: HttpMethods.Get,
          };
        },
        invalidatesTags: [Tags.UserState],
      });

      const otpLogin = builder.mutation<AuthAPIResponse, LoginOTPParams>({
        query: ({ token }) => {
          return {
            url: '/a/api/user/login-otp',
            method: HttpMethods.Post,
            data: { token },
          };
        },
        invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus],
      });

      return {
        passwordRegister,
        passwordLogin,
        socialLogin,
        socialIdentityLink,
        impersonateLogin,
        logout,
        otpLogin,
      };
    },
  });

export const loginApi = authApi;
