import type { Components, CssVarsTheme } from '@mui/material';

export const MuiCssBaseline: Components<CssVarsTheme>['MuiCssBaseline'] = {
  styleOverrides: theme => `
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${theme.palette.FilledInput.bg} inset !important;
        -webkit-text-fill-color: ${theme.palette.text.primary} !important;
        caret-color: ${theme.palette.text.primary} !important;
        color: ${theme.palette.text.primary} !important;
      }`,
};
