import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

import {
  ArrowProps,
  MenuItemProps,
  MenuLinkProps,
  NotificationIconProps,
  SubmenuItemProps,
} from './HeaderItem.model';

import whiteArrow from '../assets/whiteArrow.svg';
import whiteArrowMobile from '../assets/whiteArrowMobile.svg';

export const NotificationIndicator = styled('sup')`
  display: inline-block;
`;

export const MenuItem = styled('div')<MenuItemProps>(({ theme, seperator, isSidebarItem }) => ({
  margin: `0 ${theme.spacing(5)}`,
  position: 'relative',
  ...(seperator && {
    '&:after': {
      content: '""',
      position: 'absolute',
      borderRight: `1px solid ${theme.palette.border.strong}`,
      right: '-20px',
      margin: '30px 0',
      height: 'calc(100% - 60px)',
    },
  }),
  ...(isSidebarItem && {
    borderTop: `1px solid ${theme.palette.text.paragraphSecondary.default}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.text.paragraphSecondary.default}`,
    },
  }),
  [theme.breakpoints.up('lg')]: {
    margin: '0 14px',
  },
  [theme.breakpoints.up('xl')]: {
    margin: `0 ${theme.spacing(5)}`,
  },
}));

export const MenuLinkContainer = styled('div')<MenuLinkProps>(
  ({ theme, isButton, isSidebarItem, isSubItem, selected, active, interactive }) => ({
    ...(!isButton && {
      cursor: 'pointer',
      '& a:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      },
      '& > a': {
        padding: '2px 0',
        color: theme.palette.text.header.inverse,
        textTransform: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '16px',
        height: 'inherit',

        '&:active': {
          color: theme.palette.NavigationHeader.button.secondary?.active,
        },

        ...(interactive && {
          transition: 'background-size 0.2s ease-in-out',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '225% 0',
          backgroundPosition: '100% 100%',
          backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
        }),
        ...((selected || active) && {
          backgroundSize: `225% ${!isSubItem && !isSidebarItem ? 5 : 2}px`,
        }),
      },

      ...(isSidebarItem && {
        display: 'block',
        height: 'auto',
      }),
      ...(!isSidebarItem &&
        !isSubItem && {
          height: '66px',
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.up('lg')]: {
            height: '74px',
          },
        }),

      ...(interactive && {
        '&:hover, &:active': {
          '& > a': {
            backgroundSize: `225% ${!isSubItem && !isSidebarItem ? 5 : 2}px`,
          },
        },
      }),
    }),
  })
);

export const Title = styled('span')<MenuItemProps>(({ theme, isSidebarItem }) => ({
  fontSize: '16px',
  padding: 0,
  display: 'inline-block',
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up('lg')]: {
    whiteSpace: 'nowrap',
  },
  ...(isSidebarItem && {
    padding: `${theme.spacing(4)} 0`,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(5)} 0`,
    },
  }),
}));

export const ArrowIcon = styled('span')<ArrowProps>(({ theme, isSidebarItem, menuOpen }) => ({
  width: '14px',
  height: '8px',
  backgroundImage: `url(${whiteArrowMobile})`,
  [theme.breakpoints.up('lg')]: {
    backgroundImage: `url(${whiteArrow})`,
  },
  backgroundRepeat: 'no-repeat',
  display: 'inline-block',
  marginLeft: '6px',
  marginTop: '3px',
  transition: `transform 0.2s linear`,

  ...(isSidebarItem && menuOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }),

  ...(isSidebarItem && {
    position: 'absolute',
    right: '10px',
    top: '26px',
  }),
}));

export const SubMenu = styled('ul')<MenuItemProps>(
  ({ theme, desktopAlignment, isSidebarItem }) => ({
    background: theme.palette.NavigationHeader.background.default,
    padding: '10px 0',
    display: 'inline-block',
    position: 'relative',
    margin: 0,
    width: '100%',
    '& li': {
      padding: '10px 15px',
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        border: 'none',
      },
      '& a': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
    },
    ...(!isSidebarItem && {
      zIndex: '999',
      position: 'absolute',
      width: '100%',
      right: 'unset',
      boxShadow: 'none',
      ...(desktopAlignment === 'left' ? { left: 0 } : { right: '-20px' }),
      top: '25px',
      minWidth: '140px',
      marginTop: '30px',
      marginLeft: '-1px',
      '&:before': {
        content: '""',
        width: '100%',
        top: '-25px',
        height: '25px',
        position: 'absolute',
      },

      [theme.breakpoints.up('lg')]: {
        top: '45px',
        width: 'unset',
        '&::before': {
          height: '35px',
          top: '-35px',
        },
      },
    }),
  })
);

export const SubMenuItem = styled('li')<SubmenuItemProps>(({ theme, seperator }) => ({
  listItemType: 'none',
  '&:before': {
    [theme.breakpoints.up('lg')]: {
      ...(seperator && {
        borderTop: `1px solid ${theme.palette.border.strong}`,
      }),
    },
  },
  content: '""',
  position: 'absolute',
  top: 0,
  padding: '0 10px',
  width: 'calc(100% - 50px)',

  [theme.breakpoints.up('lg')]: {
    ...(seperator && {
      paddingTop: '15px !important',
      marginTop: '5px',
    }),
  },

  '& a': {
    display: 'block',
    listStyle: 'none',
  },
}));

export const NotificationIcon = styled('div')<NotificationIconProps>(
  ({ theme, subMenu, isBigNumber }) => ({
    width: subMenu ? '18px' : '6px',
    height: subMenu ? '18px' : '6px',
    padding: subMenu ? `${theme.spacing(1)}` : 0,
    borderRadius: '50%',
    backgroundColor: theme.palette.accent.strong,
    position: 'inherit',
    display: 'inline-block',
    marginLeft: '6px',
    textAlign: 'center',
    ...(isBigNumber && {
      width: 'auto',
      borderRadius: '40px',
    }),
  })
);

export const SParagraph = styled(Typography)(() => ({
  verticalAlign: 'super',
  fontSize: '10px',
  lineHeight: '15px',
  marginTop: '-3px',
}));

export const SButton = styled(Button)(({ theme }) => ({
  marginTop: 0,
  borderRadius: '4px',
  padding: '6px 10px',
  background: theme.palette.NavigationHeader?.button?.secondary?.default,
  border: theme.palette.NavigationHeader?.button?.secondary?.default,
  color: `${theme.palette.text.header.inverse} !important`,

  '&:hover': {
    background: theme.palette.NavigationHeader?.button?.secondary?.hover,
  },

  '&:active': {
    background: theme.palette.NavigationHeader?.button?.secondary?.active,
    border: `1px solid ${theme.palette.border.strong}`,
  },
}));
