import { styled } from '@mui/material/styles';
import { SubmenuProps, MenuItemProps, LineProps } from './Submenu';
import { Container } from '../../layout/container';

export const Wrapper = styled('div')<Partial<SubmenuProps>>(
  ({ theme, inverse, sticky, mobileStickyPx, desktopStickyPx, addTransition }) => ({
    background: inverse ? theme.palette.common.black : theme.palette.background.default,
    padding: 0,
    width: '100%',
    position: sticky ? 'sticky' : 'relative',
    top: sticky ? `${mobileStickyPx}px` : 'auto',
    transitionProperty: addTransition ? 'top' : 'none',
    transitionDuration: addTransition ? '0.3s' : 'none',
    transitionTimingFunction: addTransition ? 'ease-out' : 'none',
    zIndex: 100,
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      top: sticky ? `${desktopStickyPx}px` : 'auto',
    },
    '@media print': {
      display: 'none',
    },
  })
);

export const SContainer = styled(Container)(() => ({
  overflowX: 'visible',
  padding: 0,
}));

export const ScrollContainer = styled('div')(({ theme }) => ({
  height: '44px',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    height: '62px',
  },
}));

export const MenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 'calc(100% + 20px)',
  overflowY: 'hidden',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    height: '100%',
  },
}));

export const MenuList = styled('ul')<Partial<SubmenuProps>>(({ theme, useTabs }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: '20px',
  position: 'relative',
  margin: useTabs ? 0 : '0 20px',
  [theme.breakpoints.up('lg')]: {
    margin: '0 35px',
    padding: 0,
  },
}));

export const MenuItem = styled('li')<MenuItemProps>(({ theme, isTab, inverse }) => ({
  height: '100%',
  display: 'flex',
  flex: '0 0',
  alignItems: 'center',
  boxSizing: 'content-box',
  whiteSpace: 'nowrap',
  margin: isTab ? 0 : '0 10px',
  ...(isTab && { padding: '0 10px' }),
  [theme.breakpoints.up('lg')]: {
    margin: isTab ? '0 10px' : 'margin: 0 20px',
  },
  '&:first-child': {
    marginLeft: 0,
  },
  '&:last-of-type': {
    ...(isTab && { marginRight: 0 }),
    ...(!isTab && {
      ' &::after': {
        content: '""',
        flex: '0 0 20px',
        height: '100%',
      },
    }),
  },
  a: {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    color: inverse
      ? theme.palette.text.paragraphSecondary.inverse
      : theme.palette.text.paragraphPrimary.default,
  },
}));

export const Line = styled('div')<Partial<LineProps>>(({ theme, isTab, width, left }) => ({
  position: 'absolute',
  height: isTab ? '100%' : '5px',
  background: isTab ? theme.palette.accent.medium : theme.palette.accent.strong,
  ...(isTab ? { top: 0 } : { bottom: '20px' }),
  [theme.breakpoints.up('lg')]: {
    bottom: isTab ? '100%' : '0',
  },
  width,
  left,
  zIndex: '-1',
  transitionProperty: 'all',
  transitionDuration: '0.3s',
  transitionTimingFunction: 'ease',
}));

export const ExtraContainer = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  paddingLeft: '20px',
  paddingRight: '20px',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '35px',
    paddingRight: '35px',
  },
}));
