import { Audiences, InvestmentRequestStatus, PaymentMethodType } from '../../../enums';
import { SocketData } from '../../../types';

export type ADVICE_TYPE = 'DISCRETIONARY' | 'NON_DISCRETIONARY';

export interface InvestmentPreCreateRequest {
  adviceType: ADVICE_TYPE;
  amount: number;
  committedAmount: number;
  noteUrlHash: string;
  promotionCode?: string;
}

export interface InvestmentPreCreateBulkRequest {
  requests: InvestmentPreCreateRequest[];
}

export interface InvestmentBulkCreate extends InvestmentPreCreateRequest {
  additionalFundingSources?: additionalFundingSource[];
}
export interface InvestmentCreateBulkRequest {
  requests: InvestmentBulkCreate[];
}

export interface InvestmentPreCreateApiResponse<T = InvestmentRequestInfo> {
  code: string;
  errors?: InvestmentError;
  investment: T;
  message: string;
}

export interface InvestmentRequestInfo {
  id: string;
  createDt: number;
  timeToLive: number;
}

interface BulkInvestmentRequestInfo extends InvestmentRequestInfo {
  noteUrlHash: string;
}

export interface InvestmentGetRequestApiRequest {
  requests: InvestmentGetRequest[];
}

interface InvestmentGetRequest {
  noteUrlHash: string;
  status?: InvestmentRequestStatus;
}

export interface InvestmentPositionGetApiRequest {
  noteUrlHash: string;
  investorAccountId: number;
}

export interface InvestmentPositionGetApiResponse {
  investorPosition: {
    amount: number;
    amountShares: number;
    date: string;
    nav: number;
    noteTitle: string;
    noteUrlHash: string;
    tenderId: string;
  };
}

type InvestmentRequest = {
  id: string;
  waitlisted: boolean;
  status: InvestmentRequestStatus;
  amount: number;
  code: string;
  message: string;
};

export interface InvestmentGetRequestApiResponse {
  success: boolean;
  investments: InvestmentRequest[];
}

interface InvestmentError {
  key: string;
  description: string;
}

export interface InvestmentPreCreateBulkRequestApiResponse
  extends InvestmentPreCreateApiResponse<BulkInvestmentRequestInfo> {}

export enum additionalFundingSourceTypes {
  ROLLOVER = 'ROLLOVER',
}

export type additionalFundingSourceAttributes = {
  investmentIds: string[];
  noteUrlHash: string;
  maturityDate: string;
  availableAmount: number;
};

export type additionalFundingSource = {
  type: `${additionalFundingSourceTypes}`;
  attributes: additionalFundingSourceAttributes;
};

export interface InvestmentCreateRequest extends InvestmentPreCreateRequest {
  investmentId?: string;
  customVariables?: Partial<InvestmentCustomVariables>;
  additionalFundingSources?: additionalFundingSource[];
}

export interface InvestmentCreateApiResponse extends InvestmentPreCreateApiResponse {}

export interface InvestmentCreateBulkApiResponse {
  results: InvestmentPreCreateApiResponse<BulkInvestmentRequestInfo>;
}
export interface InvestmentCustomVariables {
  activeFilter: string;
  investmentSource: string;
  reInvest: boolean;
  searchFilters: string;
}

export interface BulkInvestmentCustomVariables extends InvestmentCustomVariables {
  investmentAmount: number;
}

export interface InvestmentRequestInformation {
  investmentId: string;
  launchDate: string;
  noteTitle: string;
  noteUrlHash: string;
  scheduled: boolean;
}

export interface InvestmentSignRequest<T = InvestmentCustomVariables> {
  bankAccountId: number | null;
  customVariables?: T;
  disclaimer: string;
  investmentId: string;
  investorAccountId: number;
  investorInitials: string;
  noteUrlHash: string;
  paymentMethod: `${PaymentMethodType}`;
}

export interface InvestmentSignResponse {
  code: string;
  errors?: InvestmentError;
  investment: InvestmentRequestInformation;
  message: string;
}

export interface InvestmentSignBulkRequest {
  requests: InvestmentSignRequest<BulkInvestmentCustomVariables>[];
}

export interface InvestmentSignBulkResponse {
  results: InvestmentSignResponse[];
}

export interface InvestmentSubscription {
  investmentId: string;
  noteUrlHash: string;
}

export interface InvestmentUpdatesSubscriptionRequest {
  authToken?: string;
  investmentSubscriptions: InvestmentSubscription[];
  groupingByInvestmentId?: boolean;
}

export interface TrackingInvestmentSubscribeUpdatesRequest {
  authToken?: string;
  noteUrlHash: string;
  investmentRequestId: string;
}

export type TrackingInvestmentSubscribeUpdatesResponse = Record<string, SocketData>;

export interface TrackingInvestmentSubscribeDetailedUpdatesResponse
  extends TrackingInvestmentSubscribeUpdatesResponse {}

export enum InvestmentSignStatusCodesError {
  BankAccountNotFound = 'bank-account-not-found',
  CryptoConversionRateUnavailable = 'crypto-conversion-rate-unavailable',
  CryptoHoldingNotFound = 'crypto-holding-not-found',
  CryptoSourceDestinationAmountMismatch = 'crypto-source-destination-amount-mismatch',
  DestinationCurrencyCrypto = 'destination-currency-crypto',
  FundAccountRequestInvalidStatus = 'fund-account-request-invalid-status',
  IncompatibleCapitalCallAmounts = 'incompatible-capital-call-amounts',
  InsufficientCryptoHoldingFunds = 'insufficient-crypto-holding-funds',
  InternalServerError = 'internal-server-error',
  InvalidCryptoHolding = 'invalid-crypto-holding',
  InvalidCryptoTradeSourceCurrency = 'invalid-crypto-trade-source-currency',
  InvalidFormat = 'invalid-format',
  InvalidFundingSource = 'invalid-funding-source',
  InvalidPaymentMethod = 'invalid-payment-method',
  InvalidUser = 'invalid-user',
  InvestmentCommittedAmountNotSupported = 'investment-committed-amount-not-supported',
  InvestmentDuplicate = 'investment-duplicate',
  InvestmentIdInUse = 'investment-id-in-use',
  InvestmentInvalidFundingSource = 'investment-invalid-funding-source',
  InvestmentInvalidInsufficientWithdrawals = 'investment-invalid-insufficient-withdrawals',
  InvestmentInvalidState = 'investment-invalid-state',
  InvestmentInvalidWaitlistState = 'investment-invalid-waitlist-state',
  InvestmentNotFound = 'investment-not-found',
  InvestmentResubscriptionNotAuthorized = 'investment-resubscription-not-authorized',
  InvestorAccountNotFound = 'investor-account-not-found',
  MultipleCryptoTradesForPairDetected = 'multiple-crypto-trades-for-pair-detected',
  NoOpenCapitalCall = 'no-open-capital-call',
  NotFound = 'not-found',
  OfferingNotFound = 'offering-not-found',
  OfferingDepositAccountMissing = 'offering-deposit-account-missing',
  OfferingNotOpen = 'offering-not-open',
  RolloverInvestmentRequestAlreadyInUse = 'rollover-investment-request-already-in-use',
  RolloverInvestmentRequestNotFound = 'rollover-investment-request-not-found',
  SourceCurrencyNotCrypto = 'source-currency-not-crypto',
  UserNotAuthorized = 'user-not-authorized',
  RolloverCannotWaitlist = 'rollover-cannot-waitlist',
}

export enum InvestmentSignStatusCodesSuccess {
  Active = 'active',
  Allocated = 'allocated',
  Created = 'created',
  Held = 'held',
  InvestmentActivationUndone = 'investment-activation-undone',
  InvestmentAddedToWaitlist = 'investment-added-to-waitlist',
  InvestmentAllocationUndone = 'investment-allocation-undone',
  InvestmentCancelled = 'investment-cancelled',
  InvestmentExpired = 'investment-expired',
  InvestmentRemovedFromWaitlist = 'investment-removed-from-waitlist',
  PostSigned = 'post-signed',
  PreCreated = 'pre-created',
  RemovedHold = 'removed-hold',
  Signed = 'signed',
  PatchInvestment = 'patch-investment',
}

export interface InvestmentUpdatesSubscriptionResponse {
  success: boolean;
  investment: Investment;
  offering: Offering;
  promotion: Promotion;
  offeringPromotion: OfferingPromotion;
  code: string;
  message: string;
}

interface Investment {
  id: string;
  waitlisted: boolean;
  rollover: boolean;
  status: string;
  amount: number;
  noteUrlHash: string;
  userId: string;
  timestamp: number;
  investorAccountId: string;
  paymentMethod: string;
  bankAccountId: string;
  payInBankAccountId: string;
  payInPaymentMethod: string;
  promotionKey: string;
}

interface Offering {
  noteUrlHash: string;
  title: string;
  spvBankAccountName: string;
  spvBankAccountNumber: string;
}

interface OfferingPromotion {
  id: string;
  promotionId: string;
  noteUrlHash: string;
  status: string;
  maximumCountLimit: number;
  audiences: Record<Audiences, Record<string, string>>;
}

interface Promotion {
  id: string;
  key: string;
  type: string;
  config: Config;
}

interface Config {
  paymentMethod: string;
  sourceBankAccountId: string;
  investmentAmountValue: string;
}

export interface LiquidationsRequest {
  urlHash: string;
  disclaimer: string;
  tenderId: string;
  investorAccountId: string;
  investorInitials: string;
  fullLiquidation?: boolean;
  amount?: number;
  amountShares?: number;
}

export interface LiquidationsResponse {
  code: string;
  liquidationRequest: {
    id: string;
  };
  message: string;
  success: boolean;
}

export interface SubmitTransferRequest {
  urlHash: string;
  investmentRequestId: string;
  bankAccountId: string;
}

export interface SubmitTransferResponse {
  success: boolean;
  investment: {
    id: string;
    createdDt: number;
  };
  code: string; // todo: this should a union type with all possible values "post-signed"....
  message: string; // todo: this should a union type with all possible values
}
