import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { media } from '../../themes/media';

import { PageFlowProps } from './PageFlow.model';

export const Header = styled.header<{ minHeight: string }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: ${props => props.minHeight};

  ${media.desktop<Partial<PageFlowProps>>`
    align-items: flex-start;
    flex-direction: row;
    position:relative;
  `}
`;

export const LogoImg = styled.img`
  display: block;
  max-width: 185px;
  height: auto;
`;

export const BackNavigation = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  position: relative;
  z-index: 10;
`;

export const LogoContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;

  justify-content: center;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${media.desktop`
    width: 500px;
  `}
`;

export const Counter = styled.div`
  align-items: center;
  cursor: pointer;
  height: 30px;
  margin-top: 15px;

  ${media.desktop`
  display:flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 0;
  `}
`;

export const ContentHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  margin-top: ${props => (props.$showLogo ? '20px' : 0)};
  width: 100%;
  text-align: left;

  ${media.tablet<{
    $maxWidth: string;
    $minHeight: string;
    $showLogo: boolean;
  }>`
    margin-left: auto;
    margin-right: auto;
    max-width: ${props => props.$maxWidth};
    text-align: center;
  `};

  ${media.desktop<{
    $minHeight: string;
    $showLogo: boolean;
  }>`
    margin-top: ${props => (props.$showLogo ? '0' : `-${props.$minHeight}`)}
  `}
`;

export const Arrow = styled(FontAwesomeIcon)`
  margin: 0 10px 0 0;
  width: 10px;
`;

export const Footer = styled.footer`
  margin-top: auto;
  padding-top: 30px;
`;
