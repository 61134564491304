import { styled } from '@mui/material/styles';

import blackArrowSmall from '../assets/blackArrowSmall.svg';
import { Button, Typography } from '@mui/material';
import {
  ArrowProps,
  MenuItemProps,
  MenuLinkProps,
  NotificationIconProps,
  SubmenuItemProps,
} from './HeaderItemLoggedOut.model';

export const NotificationIndicator = styled('sup')`
  display: inline-block;
`;

export const MenuItem = styled('div')<MenuItemProps>(({ theme, seperator }) => ({
  position: 'relative',
  ...(seperator && {
    '&:after': {
      content: '""',
      position: 'absolute',
      borderRight: `1px solid ${theme.palette.border.strong}`,
      right: '-20px',
      margin: '30px 0',
      height: 'calc(100% - 60px)',
    },
  }),
  [theme.breakpoints.up('lg')]: {
    margin: '0 14px',
  },
  [theme.breakpoints.up('xl')]: {
    margin: `0 ${theme.spacing(5)}`,
  },
}));

export const MenuLinkContainer = styled('div')<MenuLinkProps>(
  ({ theme, isButton, inverse, isSidebarItem, interactive, isSubItem }) => ({
    ...(!isButton && {
      cursor: 'pointer',
      '& a:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      },
      '& > a': {
        fontWeight: 300,
        color: inverse ? theme.palette.text.header.inverse : theme.palette.text.header.default,
        textTransform: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '21px',
        height: 'inherit',
        [theme.breakpoints.up('lg')]: {
          fontSize: '14px',
        },

        ...(interactive && {
          transition: 'background-size 0.2s ease-in-out',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '225% 0',
          backgroundPosition: '100% 100%',
          backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
        }),
      },
      ...(isSidebarItem && {
        alignItems: 'center',
        display: 'flex',
        height: 'auto',
      }),
      ...(!isSidebarItem &&
        !isSubItem && {
          height: '64px',
          display: 'flex',
          alignItems: 'center',
        }),
    }),
    ...(interactive && {
      '&:hover, &:active': {
        '& > a': {
          backgroundSize: `225% ${!isSubItem && !isSidebarItem ? 5 : 2}px`,
        },
      },
    }),
  })
);

export const Title = styled('span')<MenuItemProps>(({ theme, isSidebarItem }) => ({
  fontSize: '16px',
  padding: 0,
  display: 'inline-block',
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up('lg')]: {
    whiteSpace: 'nowrap',
  },
  ...(isSidebarItem && {
    padding: `${theme.spacing(4)} 0`,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(5)} 0`,
    },
  }),
}));

export const ArrowIcon = styled('span')<ArrowProps>(({ theme, menuOpen }) => ({
  width: '12px',
  height: '7px',
  backgroundImage: `url(${blackArrowSmall})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'inline-block',
  marginLeft: '10px',
  ...(menuOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }),
  [theme.breakpoints.up('lg')]: {
    backgroundImage: `url(${blackArrowSmall})`,
    backgroundPosition: 'center',
    width: '7px',
    height: '4px',
  },
}));

export const SubMenu = styled('ul')<MenuItemProps>(
  ({ theme, menuOpen, isSidebarItem, desktopAlignment }) => ({
    background: theme.palette.background.default,
    transition: 'height 0ms 200ms, opacity 200ms 0ms',
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    position: 'relative',
    margin: 0,
    width: '100%',
    ...(menuOpen && {
      height: 'auto',
      opacity: 1,
      transition: 'height 0ms 0ms, opacity 200ms 0ms',
      padding: '10px 0',
    }),
    [theme.breakpoints.up('lg')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    li: {
      padding: '10px 0',
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        border: 'none',
        padding: '10px 15px',
      },
      a: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
    },
    ...(!isSidebarItem && {
      zIndex: '999',
      position: 'absolute',
      width: 'unset',
      right: 'unset',
      boxShadow: 'none',
      ...(desktopAlignment === 'left' ? { left: 0 } : { right: '-20px' }),
      top: '25px',
      minWidth: '140px',
      marginTop: '30px',
      marginLeft: '-1px',
      [theme.breakpoints.up('lg')]: {
        top: '45px',
        width: 'unset',
        '&::before': {
          height: '35px',
          top: '-35px',
        },
      },
    }),
  })
);

export const SubMenuItem = styled('li')<SubmenuItemProps>(({ theme, seperator }) => ({
  listItemType: 'none',
  '&:before': {
    [theme.breakpoints.up('lg')]: {
      ...(seperator && {
        borderTop: `1px solid ${theme.palette.border.strong}`,
      }),
    },
  },
  content: '""',
  position: 'absolute',
  top: 0,
  padding: '0 10px',
  width: 'calc(100% - 50px)',

  [theme.breakpoints.up('lg')]: {
    ...(seperator && {
      paddingTop: '15px !important',
      marginTop: '5px',
    }),
  },

  '& a': {
    display: 'block',
    listStyle: 'none',
    fontWeight: '500',
    fontSize: `18px !important`,
    [theme.breakpoints.up('lg')]: {
      fontSize: `16px !important`,
    },
  },
}));

export const NotificationIcon = styled('div')<NotificationIconProps>(
  ({ theme, subMenu, isBigNumber }) => ({
    width: subMenu ? '18px' : '6px',
    height: subMenu ? '18px' : '6px',
    padding: subMenu ? `${theme.spacing(1)}` : 0,
    borderRadius: '50%',
    backgroundColor: theme.palette.accent.strong,
    position: 'inherit',
    display: 'inline-block',
    marginLeft: '6px',
    textAlign: 'center',
    ...(isBigNumber && {
      width: 'auto',
      borderRadius: '40px',
    }),
  })
);

export const SParagraph = styled(Typography)(() => ({
  verticalAlign: 'super',
  fontSize: '10px',
  lineHeight: '15px',
  marginTop: '-3px',
}));

export const SButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  padding: `6px 10px`,
  background: theme.palette.NavigationHeader?.button?.secondary?.default,
  border: `1px solid ${theme.palette.NavigationHeader?.button?.secondary?.default}`,
  color: `${theme.palette.text.header.inverse} !important`,
  '&:hover,   &:active': {
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
  },
}));
